import React from 'react'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import ImageVideo from './components/ImageVideo'
import FeaturedEntries from './components/flexContent/featuredEntries'
import WorkWithUsBlock from './components/flexContent/workWithUsBlock'
// eslint-disable-next-line max-len
const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const returnSlug = (pageType, slug) => {
  switch (pageType) {
  case 'Case Study':
    return `/case-study/${ slug }`
  case 'Blog':
    return `/blog/${ slug }`
  case 'News':
    return `/news/${ slug }`
  case 'Home Page':
    return '/'
  default:
    return `/${ slug }`
  }
}

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.HEADING_1]: (node, children) => <h2 className="font-serif text-6xl">{children}</h2>,
    [BLOCKS.HEADING_2]: (node, children) => <h2 className="font-serif text-4xl">{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3 className="tracking-super text-lg font-bold uppercase">{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4 className="tracking-super text-lg font-bold uppercase">{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5 className="tracking-super text-base font-bold uppercase">{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6 className="tracking-super text-sm font-bold uppercase">{children}</h6>,
    [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <ImageVideo objectFit="cover" file={node.data.target} />
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      return (
        <div className="not-prose md:-mx-24 lg:-mx-32 my-8 -mx-8">
          {node.data.target.__typename === 'ContentfulWorkWithUsBlock'
            ? (
              <WorkWithUsBlock data={node.data.target} />
            )
            : node.data.target.__typename === 'ContentfulFlexPage'
              ? (
                <FeaturedEntries data={node.data.target} />
              )
              : (
                <div></div>
              )
          }
        </div>
      )
    },
    [INLINES.EMBEDDED_ENTRY]: node => {
      return (
        <a className="ml-1" href={returnSlug(node.data.target.pageType, node.data.target.slug)} >{node.data.target.title}</a>
      )
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return (
        <>
          {node.data.target.__typename === 'ContentfulMasterWorkList'
            ? (
              <a href='/work' >{children}</a>
            )
            : (
              <a href={node.data ? returnSlug(node.data.target.pageType, node.data.target.slug) : '/' } >{children}</a>
            )
          }
        </>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a href={node.data.uri} target="_blank" >{children}</a>
      )
    },
  },
}

const parseRichText = text => {
  return renderRichText(text, options)
}

export default parseRichText
