import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import richTextParserSimple from '../richTextParserSimple'

const Template = props => {
  const { pageContext } = props
  return (
    <Layout>
      <SEO title={ pageContext.title } />
      <div className="px-site mt-40 mb-12">
      </div>
      {pageContext.content && (
        <article className="px-site">
          <h1 className='h2'>{ pageContext.title }</h1>
          <div className="prose-p:font-light prose-p:max-w-prose prose-a:underline-offset-1 prose-a:decoration-purple prose-a:decoration-2 prose-a:underline prose-a:align-baseline prose-ul:list-disc prose-ol:list-decimal prose-h2:my-8 prose-headings:max-w-screen-xl prose-lg" >{richTextParserSimple(pageContext.content)}</div>
        </article>
      )}
    </Layout>
  )
}

export default Template
